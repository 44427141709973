.topbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  background-color: #822280;
  box-sizing: border-box;
}

.topbarContainer {
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: #822280;
  box-sizing: border-box;
}

.logoContainer,
.titleContainer,
.networkContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; /* Ensure the containers take the full height */
  box-sizing: border-box;
}
.loginContainer {
  display: flex;
  align-items: center;
  justify-content: end;
  height: 100%; /* Ensure the containers take the full height */
  box-sizing: border-box;
}



.logoContainer {
  flex-direction: column; /* Stack logo and deploy text vertically */
  width: 160px; /* Fixed width */
  align-items: start;
}
.logoContainer2 {
  display: flex;
  flex-direction: column; /* Stack logo and deploy text vertically */
  width: auto; /* Fixed width */
  align-items: center;
}

.logo {
  width: 70px;
}

.titleContainer {

  flex-direction: column;
  justify-content: space-between;
}

.title {
  max-width: 150px;
  width: 100%;
  height: auto;
  cursor: pointer;
}

.loginContainer {
  width: 160px; /* Fixed width */
}

.navlinksContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-color: #822280;
}

.navButtonContainer {
  display: flex;
}

.navButton,
.menuButton {
  color: white;
  text-decoration: none;
  text-align: center;
  border-radius: 5px;
  width: 85px;
  background-color: #942a94;
  font-size: 12px;
  padding: 0 1px;
  margin: 0 1px;
  box-sizing: border-box;
  transition: background-color 0.3s, transform 0.2s;
  cursor: pointer;
}

.navButton:hover,
.menuButton:hover {
  background-color: #a73ea7;
  transform: scale(1.1);
}

.activeNavButton {
  background-color: #b950b9;
}

.deployLink {
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  margin-top: 5px;
  text-decoration: none;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s;
}

.deployLink:hover {
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  transform: scale(1.1);
}

.deployLink:active {
  background-color: rgba(255, 255, 255, 0.4);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.7);
  transform: scale(1.2);
}

/* Responsive styles */
@media (max-width: 700px) {
  .navButtonContainer {
    display: flex;
    align-items: center;
  }

  .navButton,
  .menuButton {
    width: 50px; /* Smaller width for buttons */
    font-size: 10px; /* Smaller font size */
    padding: 2px; /* Adjust padding */
    margin: 2px 2px; /* Adjust margin */
  }



  .menuButton {
    display: none; /* Hide menu button */
  }


}
/* Hamburger menu button */
.hamburgerButton {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.hamburgerIcon {
  width: 30px;
  height: 30px;
}

/* Show/Hide Menu on Mobile */
.showMenu {
  display: flex !important;
  flex-direction: column;
}

@media (max-width: 700px) {
  .logo {
    width: 56px; /* Reduced by 20% */
  }

  .title {
    max-width: 150px; /* Reduced by 20% */
  }

  /* Hide nav links and show hamburger */
  .navlinksContainer {
    display: none;
  }

  .hamburgerButton {
    display: flex;
  }

  .showMenu {
    display: flex;
    position: absolute;
    top: 100px;
    right: 0;
    background-color: #822280;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    border: 1px solid #fff;
    border-radius: 5px;
    z-index: 10;
  }

  .navButton {
    margin: 10px 0;
    padding: 12px;
    background-color: #942a94;
    width: 100%;
  }
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
