/* General Card Styles */
.contestCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 20px;
  margin: 20px 0;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  font-size: 12px;
}

.contestCard:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.galleryContainer{ 
  border-radius: 10px;
  border: 0.5px solid #822280;
  width: 100%;
}

/* Title and Etherscan Link */
.h3WithEtherscan {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.contestId {
  margin: 0;
  display: flex;

}

.factoryVersion {
  display: inline-flex;
  align-items: center;
  font-size: 1em;
  color: #666;
  margin-right: 10px;
}

.factoryIcon {
  width: 1.5em; /* Set the width to match the height of the text */
  height: auto; /* Maintain the aspect ratio */
  margin-right: 5px; /* Space between the icon and the version text */
  top: 5
};


.etherScanLink {
  display: flex;
  align-items: center;
}

.etherscanLogo {
  height: 1.5em;
  width: auto;
  transition: transform 0.5s ease, filter 0.3s ease;
}

.etherscanLogo.animate {
  animation: jumpAndWiggle 0.5s ease 1s;
}

.etherscanLogo:hover {
  filter: brightness(1.2);
  transform: scale(1.05);
}

.etherscanLogo:active {
  transform: scale(0.95);
}

.smallEtherscanLogo {
  width: 15px;
  vertical-align: middle;
  transition: transform 0.5s ease, filter 0.3s ease;
}

.smallEtherscanLogo.animate {
  animation: jumpAndWiggle 0.5s ease 1s;
}

.smallEtherscanLogo:hover {
  filter: brightness(1.2);
  transform: scale(1.05);
}

.smallEtherscanLogo:active {
  transform: scale(0.95);
}

/* Prize Pool */
.prize {
  font-size: 1.2em;
  color: #822280;
  margin: 10px 0px 25px 0;
}

.prizeEmoji {
  display: inline-block;
  transition: transform 0.5s ease, filter 0.3s ease;
  cursor: pointer;
}

.prizeEmoji.animate {
  animation: jumpAndWiggle 0.5s ease 1s;
}

.prizeEmoji:hover {
  filter: brightness(1.2);
  transform: scale(1.05);
}

.prizeEmoji:active {
  transform: scale(0.95);
}

/* Info Panel */
.infoPanel {
  background-color: rgb(235, 234, 234);
  margin: 0;
  border-radius: 8px;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.15);
  text-align: left;
  font-size: 12px;
  color: #666;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
  box-sizing: border-box;
  opacity: 0;
  padding: 5px;
}

.detailsToggle {
  cursor: pointer;
  color: #822280;
  margin: 5px;
  text-align: center;
  margin-top: 25px;
}

.infoPanel.show {
  display: grid;
  max-height: 500px;
  opacity: 1;
  transition: max-height 0.5s ease-in, opacity 0.5s ease-in;
}

.infoItem {
  display: flex;
  justify-content: space-between;
  margin:0px 5px;
}

/* Button Bar */
.buttonBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.button {
  flex: 1;
  height: 40px;
  font-size: 0.9em;
  background-color: #822280;
  color: white;
  border: none;
  border-radius: 5px;
  margin: 0 5px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.button:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  background-color: #ff007a;
}

.button:active {
  transform: scale(0.95);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.button:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.button:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

@keyframes jumpAndWiggle {
  0% { transform: translateY(0); }
  50% { transform: translateY(-5px) rotate(5deg); }
  100% { transform: translateY(0); }
}
