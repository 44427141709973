@keyframes slideDown {
  from {
    opacity: 0;
    
  }
  to {
    opacity: 100;

  }
}

.buttonStyle, .buttonStyleDank, .buttonStyleConnect, .buttonStyleDisconnect {
  display: flex;
  color: #fff;
  font-size: 12px;
  justify-content: center;
  width: 150px;
  padding: 10px 10px;
  border: 2px solid black;
  border-radius: 25px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  align-items: center;
  margin: 2px;
  text-decoration: none;
  
}

.buttonStyle {
  background-color: #ff007a;
}

.buttonStyleDank {
  background-color: #007BFF;
}

.buttonStyleConnect {
  background-color: #00A550;
}
.buttonStyleDisconnect {
  background-color: #E53935;
}

.buttonStyle:hover, .buttonStyleDank:hover, .buttonStyleConnect:hover, .buttonStyleDisconnect:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.18);
}

.buttonStyle:active, .buttonStyleDank:active, .buttonStyleConnect:active, .buttonStyleDisconnect:active {
  transform: translateY(1px);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.22);
}

.buttonStyle:hover, .buttonStyleDank:hover, .buttonStyleConnect:hover, .buttonStyleDisconnect:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.18);
}

.buttonStyle:active, .buttonStyleDank:active, .buttonStyleConnect:active, .buttonStyleDisconnect:active {
  transform: translateY(1px);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.22);
}


.buttonStyle p, .buttonStyleDank  p, .buttonStyleConnect p, .buttonStyleDisconnect p   {
  padding: 0;
  margin: 0;
  text-align: justify;
}


.walletIcon {
  width: 20px; /* Adjust icon size as needed */
  padding-left: 5px;
}

@media (max-width: 700px) {
  .buttonStyle span {
    display: none; /* Hide the "Wallet" text on narrow screens */
    padding: 0;
  }
  .buttonStyle {
    margin-right: 5px;
    width: 50px;
  }
}

.connectContainer {
  position: relative; /* Ensure dropdown is positioned relative to this container */
  /* Other styles remain unchanged */
}

.dropdownContent {
  position: absolute;
  top: 110px;
  right: 0;
  display: none;
  width: 250px;
  height: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(145deg, #f8f8f8, #e6e6e6); /* Updated subtle gradient */
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 10;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 15px;
  border: 1px solid #822280;
}

.show {
  display: flex;
  animation: slideDown 2s ease-out forwards;
}

h3 {
  margin: 10px 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1); /* Adding text shadow for header */

}

.connectedAccountsTitle {
  width: 100%; /* Full width of the container */
  text-align: center; /* Center the text */
  color: #822280; /* Purple color */
  font-size: 10px; /* Suitable font size */
  margin: 0; /* Space below the title */
}


.accountsList {
  list-style-type: none; /* Remove list bullets */
  padding: 0; /* Remove default padding */
  width: 100%; /* Full width */
  text-align: center; /* Center align text */
  margin: 0;
}

.accountItem {
  background-color: #e7e9ed;
  padding: 5px 10px;
  margin: 10px 0;
  border-radius: 15px;
  font-size: 12px;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.2); /* Sunken effect */
  transition: background-color 0.3s, color 0.3s;
}

.accountItem:hover, .selectedAccount {
  background-color: #822280; /* Highlight on hover */
  color: #e7e9ed;
  cursor: pointer;
}

.selectedAccount {
  border: 2px solid #ff007a; /* Highlight selected with a bright border */
}
