.networkSelector {
    position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Align to the right */
  justify-content: center;

}

.networkSelectorButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2e6ff; /* Same style as .buttonStyleConnect */
  color: #fff;
  width: 50px; /* Adjust width for the image */
  height: 38px; /* Ensure button is square for a consistent tile look */
  padding: 10px;
  border: 2px solid black;
  border-radius: 25px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.12);
}

.networkSelectorButton:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.18);
}

.networkSelectorButton:active {
  transform: translateY(1px);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.22);
}

/* Style for network logos */
.networkLogo {
  width: 30px;
  height: 30px;
  object-fit: contain; /* Ensure the logo scales properly within the button */
}

/* Dropdown menu styles */
.dropdownContent {
  position: absolute;
  top: 57px;
  right: -120px; /* Align to the right edge */
  width: 250px;
  height: auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(145deg, #f8f8f8, #e6e6e6);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 10;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 15px;
  border: 1px solid #822280;
  text-align: center;
}

.show {
  display: flex;
}

/* Dropdown items style */
.accountItem {
  display: flex;
  align-items: center;
  background-color: #e7e9ed;
  padding: 8px 10px;
  margin: 5px 0;
  border-radius: 15px;
  font-size: 12px;
  width: 160px;
  text-align: center;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.accountItem:hover {
  background-color: #822280; /* Highlight on hover */
  color: #e7e9ed;
}

/* Style for logos inside dropdown */
.dropdownNetworkLogo {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

/* Slide down animation */
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 700px) {

/* Dropdown menu styles */
.dropdownContent {


  right: -67px; /* Align to the right edge */


}
}