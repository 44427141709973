.pageContainer {
  width: auto;
  background-color: #f2e6ff;
  text-align: center;
  font-size: 12px;
}

.contentContainer {
  display: flex;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

p {
  padding: 0 25px;;
}

.contestDisplayFeedContainer {
  width: 100%;
  max-width: 500px;
  min-width: 300px;
  position: relative;
  font-family: 'Comic Sans MS', cursive, sans-serif;
  text-align: center;
  background-color: #f2e6ff;
  padding: 10px;
  box-sizing: border-box;
  margin: 0 auto;
  display: block;
  border-radius: 25px;
  overflow: hidden;
  z-index: 0;
}

.submissionsList {
  flex-direction: column;
  margin-top: 20px;
}

.submission {
  padding: 15px;
  border: 2px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  min-width: 300px;
}

.submission h2 {
  margin: 0;
  color: #333;
}

.submission p {
  margin: 10px 0 0;
  color: #666;
}
