.createContainer {
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: aliceblue;
}

.buttonContainer {
  display: flex;
  margin-bottom: 20px;
  max-width: 500px;
  justify-content: space-between;
}

.buttonContainer .button {
  padding: 10px;
  width: 100px;
  margin: 0 10px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.2s;
}

.buttonContainer .activeButton {
  background-color: #942a94;
  color: white;
  transform: scale(1.1);
}

.buttonContainer .inactiveButton {
  background-color: #ddd;
  color: black;
}

.buttonContainer .button:hover {
  background-color: #a73ea7;
  transform: scale(1.1);
}
