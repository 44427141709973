
.formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    padding: 0px;
    margin: 20px 0;
    border-radius: 10px;
    
    width: 100%;
    max-width: 500px;
}

input[type="file"] {
    margin-bottom: 15px;
}

.uploadButton, .fileButton {
    width: 100%;
    padding: 10px;
    font-size: 1em;
    color: #fff;
    background-color: #822280;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

.fileButton {
    background-color: #666;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.uploadButton:hover {
    background-color: #ff007a;
    transform: scale(1.05);
}

.uploadButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.uploadButton:active {
    transform: scale(0.95);
}

.loading {
    margin-top: 10px;
    font-size: 0.9em;
    color: #666;
}
