.pepeToPink {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  width: 100%;
  margin: 10px auto;
  box-sizing: border-box;
}

.colorSwatch {
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* ... existing styles ... */

.colorSwatch {
  width: 36px;
  height: 36px;
  border: 1px solid #ccc;
  border-radius: 5px;
}



.colorInput {
  width: 36px;
  height: 36px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

/* Magnifier styles */
.imageWrapper {
  position: relative;
  display: inline-block;
}

.magnifier {
  position: absolute;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 2px solid #822280;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  pointer-events: none;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1000;
}




@media (min-width: 1200px) {
  .pepeToPink {
    max-width: 1200px;
  }
}

.uploadContainer {
  margin: 0 0 10px;
  text-align: center;
  padding: 15px;
  border: 2px dashed #822280;
  border-radius: 10px;
  cursor: pointer;
  transition: border-color 0.3s, background-color 0.3s;
}

.uploadContainer:hover {
  border-color: #ff007a;
  background-color: #f9f9f9;
}

.uploadButton {
  padding: 8px 16px;
  background-color: #822280;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
  font-size: 14px;
  margin-top: 8px;
}

.uploadButton:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  background-color: #ff007a;
}

.uploadButton:active {
  transform: scale(0.95);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.settingsContainer {
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin-top: 10px;
  position: relative;
}

.settingsContainer h3 {
  margin-top: 0;
  color: #822280;
  font-size: 16px;
}

.resetButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #ff007a;
  font-size: 18px;
  cursor: pointer;
}

.resetButton:hover {
  color: #ff4d94;
}

.targetColorsContainer {
  margin-top: 10px;
}

.imageContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 100%;
}

.imageBox {
  width: calc(50% - 5px);
  position: relative;
  text-align: center;
  border: 1px solid #ccc;
  padding: 5px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #ffffff;
}

.imageBox h3 {
  margin-top: 0;
  color: #822280;
  text-align: center;
  font-size: 14px;
}

.iconContainer {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  gap: 5px;
}

.iconButton {
  color: #822280;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 6px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  font-size: 14px;
}

.iconButton:hover {
  background-color: rgba(255, 255, 255, 1);
  transform: scale(1.1);
}

.iconButton:active {
  transform: scale(0.9);
}

.image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.placeholder {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  color: #999;
  font-size: 14px;
  border-radius: 8px;
}

.controlIcons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.slidersContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  margin-top: 10px;
  box-sizing: border-box;
}

.sliderGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}

@media (min-width: 750px) {
  .sliderGroup {
    width: calc(50% - 10px);
  }
}

.sliderContainer {
  flex: 1 1 calc(50% - 10px);
  position: relative;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.labelContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.labelContainer label {
  color: #822280;
  font-weight: bold;
  margin-bottom: 2px;
  font-size: 12px;
}

.infoIcon {
  margin-left: 5px;
  color: #ff007a;
  cursor: pointer;
  font-size: 12px;
  display: inline-block;
  position: relative;
}

.tooltip {
  position: absolute;
  background-color: #ffffff;
  color: #822280;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  font-size: 11px;
  width: 200px;
  z-index: 10;
  white-space: normal;
  transition: opacity 0.5s;
  opacity: 1;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 0%;
}

.tooltip.fade-out {
  opacity: 0;
}

.tooltip::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
}

.presetContainer {
  width: 100%;
  padding: 5px;
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #ccc;
}

.presetLabel {
  color: #822280;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
}

.presetOptions {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.presetOption {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: #822280;
  font-size: 12px;
  flex: 1 1 calc(50% - 10px);
}

.presetOption input {
  margin-right: 5px;
}

.formGroupHalf {
  display: flex;
  flex-direction: column;
  width: 48%;
}

.formGroupFull {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.colorSwatch {
  width: 100%;
  height: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.targetRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 4%;
}

.spacer {
  height: 10px;
}

.controlsContainer {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
}

.targetGroup {
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  position: relative;
  margin-bottom: 10px;
}

.targetGroupHeader {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.select {
  width: 100%;
  padding: 5px;
  border: 1px solid #822280;
  border-radius: 5px;
  background-color: #ffffff;
  color: #822280;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.select:focus {
  outline: none;
  border-color: #ff007a;
}

.input {
  width: 100%;
  padding: 5px;
  color: #822280;
  border: 1px solid #822280;
  border-radius: 5px;
  background-color: #ffffff;
  margin-top: 5px;
}

.slider {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: 8px;
  border-radius: 5px;
  background: #e4d5e9;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  margin-top: 5px;
  margin-bottom: 5px;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #822280;
  cursor: pointer;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

.slider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #822280;
  cursor: pointer;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

.colorInput {
  width: 100%;
  padding: 5px;
  border: 1px solid #822280;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
  margin-bottom: 10px;
}

.removeButton {
  background: none;
  border: none;
  color: #ff007a;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s;
}

.removeButton:hover {
  color: #ff4d94;
}

.errorMessage {
  color: red;
  font-weight: bold;
  margin-bottom: 5px;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.loadingContainer p {
  font-size: 14px;
  color: #822280;
}

.button {
  padding: 5px 10px;
  background-color: #822280;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 5px;
  transition: background-color 0.3s;
  margin-bottom: 10px;
}

.button:hover {
  background-color: #ff007a;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  position: relative;
}

.enlargedImage {
  max-width: 90vw;
  max-height: 90vh;
  border-radius: 8px;
}

.closeModalButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.7);
  border: none;
  color: #ff007a;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
}

.closeModalButton:hover {
  background: rgba(255, 255, 255, 1);
}

/* Media query for screens less than 750px */
@media (max-width: 750px) {
  .imageBox {
    width: 100%;
  }

  .image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }

  .iconButton {
    font-size: 12px;
    padding: 5px;
  }

  .imageBox h3 {
    font-size: 12px;
  }

  .slidersContainer {
    flex-direction: column;
  }

  .sliderGroup {
    width: 100%;
  }

  .sliderContainer {
    flex: 1 1 100%;
  }

  .presetOption {
    flex: 1 1 100%;
  }

  .targetGroup,
  .settingsContainer {
    grid-column: span 1;
  }

  .formGroupHalf {
    width: 100%;
    margin-top: 10px;
  }

  .targetRow {
    flex-direction: column;
    gap: 0;
  }

  h2,
  h3,
  label,
  p,
  button {
    font-size: 85%;
  }

  .tooltip {
    top: auto;
    bottom: -45px;
    left: 0;
  }
}
