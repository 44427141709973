.promptGeneratorContainer {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    min-width: 300px;
    width: 100%;
    margin: 20px auto;
    box-sizing: border-box;
  }
  
  .promptGenerator {
    font-size: 14px;
  }
  
  .formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .input {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .button {
    padding: 10px 20px;
    background-color: #822280;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
  }
  
  .button:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    background-color: #ff007a;
  }
  
  .generatedPrompt {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  