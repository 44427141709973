
.top {
    min-height: 100vh;
    
}


/* App.css */
@font-face {
    font-family: '2Dumb';
    src: url('../public/fonts/2Dumb.ttf') format('truetype');
  }
  
  @font-face {
    font-family: '3Dumb';
    src: url('../public/fonts/3Dumb.ttf') format('truetype');
  }
  
   h1 {
    font-family: '3Dumb', sans-serif; /* Use the custom font families */
  }
  
    h2, h3, h4, h5, h6{
    font-family: '2Dumb', sans-serif; /* Use the custom font families */
  }

  a, button, li, p, span, strong, label {
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  