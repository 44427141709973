@keyframes colorShift {
  0% {background-color: #ff7e5f;}
  50% {background-color: #feb47b;}
  100% {background-color: #ff7e5f;}
}



h4 {
    color: #ff007a;
}

.footer {
    background-color: #822280;
    position: relative;
    color: white;
    text-align: center;
    padding: 20px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.footerContent {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.socialLinks ul {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 20px;
}

.socialLinks h4 {
    margin-bottom: 15px;
}

.icon {
    width: 32px;
    height: 32px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}
button {
    margin: 0;
    padding: 0;
}

.icon:hover {
    transform: scale(1.1);
}

.copyRight {
    font-size: 0.8rem;
}
