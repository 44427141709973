.feed {
  text-align: center;
  margin-bottom: 0;
}

.contestDisplayFeedContainer {
  position: relative;
  background-color: #f2e6ff;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  display: block;
  border-radius: 25px;
  overflow: hidden;
  z-index: 0;
  height: auto;
}

.contestDisplayFeedContainer::before,
.contestDisplayFeedContainer::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 40px;
  pointer-events: none;
}

.contestDisplayFeedContainer::before {
  top: 0;
  background: linear-gradient(to bottom, rgba(242, 230, 255, 1) 0%, transparent 100%);
}

.contestDisplayFeedContainer::after {
  bottom: 0;
  background: linear-gradient(to top, rgba(242, 230, 255, 1) 0%, transparent 100%);
}

.scrollableContests {
  max-height: calc(120vh - 300px); /* Adjust */
  overflow-y: auto;
  scroll-behavior: smooth;
  padding-bottom: 20px; /* Space for last items not to be hidden */
}

/* Hide the scrollbar */
.scrollableContests::-webkit-scrollbar {
  display: none;
}

.scrollableContests {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
