.dankifyContainer {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  max-width: 900px;
  width: 100%;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

/* Each major block: background, stickers, canvas */
.section {
  width: 100%;
  margin-bottom: 20px;
}
.sectionHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 10px;
}
.leftHeading {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
.collapseButton {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  line-height: 1;
  padding: 0 5px;
}
.sectionBody {
  margin-top: 5px;
}

/* BACKGROUND TABS */
.tabsContainer {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 10px;
}
.tabButton {
  padding: 6px 12px;
  background-color: #ddd;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s;
}
.tabButton:hover {
  background-color: #ccc;
}
.activeTab {
  background-color: #822280;
  color: #fff;
}

.tabContent {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  max-height: 360px; /* allow scrolling if needed */
  padding: 15px;
  box-sizing: border-box;
  overflow-y: auto;
}
@media (max-width: 600px) {
  .tabContent {
    max-height: 200px; /* smaller max-height on smaller screens */
  }
}
.tabContent::-webkit-scrollbar {
  width: 8px;
}
.tabContent::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.tabContent::-webkit-scrollbar-thumb {
  background-color: #822280;
  border-radius: 4px;
}

.colorPickerWrapper {
  max-width: 220px;
  width: 100%;
  margin: 0 auto;
}

.tabPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

/* Upload container */
.uploadContainer {
  text-align: center;
  padding: 15px;
  border: 2px dashed #822280;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 10px;
  transition: border-color 0.3s, background-color 0.3s;
}
.uploadContainer:hover {
  border-color: #ff007a;
  background-color: #f9f9f9;
}

/* Stock images container if you want a flex grid of images. */
.stockImageList {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: center;
}

/* 
  Sticker container: continuous feed with headings 
  We'll also allow scrolling if there are many categories. 
*/
.stickerContainer {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  max-height: 320px;
  padding: 15px;
  box-sizing: border-box;
  overflow-y: auto;
}
.stickerContainer::-webkit-scrollbar {
  width: 8px;
}
.stickerContainer::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.stickerContainer::-webkit-scrollbar-thumb {
  background-color: #822280;
  border-radius: 4px;
}

/* Sticker dropdown */
.stickerSelect {
  margin-left: auto;
  padding: 4px 8px;
  font-size: 14px;
}

.imageList {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: center;
}

/* CANVAS container */
.imageContainer {
  width: 100%;
  max-width: 500px;
  aspect-ratio: 1;
  margin: 0 auto;
  position: relative;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  background-color: #f0f0f0;
}
.placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 18px;
}

/* Bottom toolbar */
.bottomToolbar {
  max-width: 500px;
  width: 100%;
  margin: 10px auto 0 auto;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  gap: 5px;
  box-sizing: border-box;
}
.toolbarButton {
  flex: 1;
  text-align: center;
  padding: 8px;
  background-color: #822280;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  transition: background-color 0.3s, transform 0.2s;
}
.toolbarButton:hover {
  background-color: #a73ea7;
}
.toolbarButton:active {
  background-color: #c15dc1;
  transform: scale(0.96);
}
@media (max-width: 480px) {
  .toolbarButton span {
    display: none;
  }
}

/* Shared thumbnail style */
.thumbnail {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border: 1px solid #ccc;
  border-radius: 6px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .thumbnail {
    width: 60px;
    height: 60px;
  }
}

/* TEXT MODAL */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalContent {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 320px;
  text-align: left;
}
.textOptions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
.uploadButton {
  padding: 8px 16px;
  background-color: #822280;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}
.uploadButton:hover {
  background-color: #a73ea7;
}
.uploadButton:active {
  background-color: #c15dc1;
  transform: scale(0.96);
}
