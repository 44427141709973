.memeContestGalleryWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 600px;
  margin: auto;
  font-size: 12px;
}

.memeContestGalleryWrapper,
.submissionDetail {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  cursor: grab;
}

.memeContestGalleryWrapper:active,
.submissionDetail:active {
  cursor: grabbing;
}

.arrowButton {
  width: 25px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 10;
  background-color: transparent;
  border: none;
  color: #822280;
}

.arrowButton:first-of-type {
  left: -5px;
}

.arrowButton:last-of-type {
  right: -5px;
}

.scrollRegionLeft, .scrollRegionRight {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50px;
  z-index: 5;
  cursor: pointer;
}

.scrollRegionLeft {
  left: 0;
}

.scrollRegionRight {
  right: 0;
}

.memeContestGallery {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  gap: 20px;
  padding: 0px;
  width: 100%;
}

.memeContestGallery::-webkit-scrollbar {
  display: none;
}

.memeContestGallery {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.submissionDetail {
  flex: 0 0 auto;
  width: 100%;
  scroll-snap-align: center;
  display: flex;
  flex-direction: column;
  color: white;
  text-decoration-line: none;
  width: 100%;
}

.entryBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #822280;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  height: 30px;
  z-index: 20;
  padding: 0 10px;
  font-size: 10px;
}

.leftSection {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.rightSection {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 10px;
}

.middleSection {
  flex: 1;
  display: flex;
  align-items: center;
  
  font-size: 10px;
}

.circleID {
  position: relative;
  width: 60px;
  height: 20px;
  border-radius: 10px;
  background-color: #ff007a;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
  font-weight: bold;
}

.tooltip {
  position: absolute;
  bottom: 100%; /* Show above the button */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  font-size: 12px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.imageButton:hover .tooltip {
  opacity: 1;
  visibility: visible;
  z-index: 30;
}

.detailText {
  padding: 0;
}

.etherScanLink {
  margin: 0;
}

.bottomGalleryBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #822280;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 0px;
  color: #fff;
  padding: 8px;
}

.bottomGalleryBar p {
  font-size: 10px;
  margin: 0;
}

.leftAlign {
  text-align: left;
  align-content: center;
  flex: 1;
}

.rightAlign {
  text-align: right;
  flex: 1;
}

.memeImageContainer {
  background-color: #f1d7f0;
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  overflow: hidden;
  height: 20vh;
  margin: auto;
}

.memeImage {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: 100%;
  max-height: 700px;
  object-fit: fill;
}

.etherScanLink {
  color: #fff;
  text-decoration: underline;
  background-color: transparent;
  margin: 10px;
}

.etherScanLink a {
  color: white;
  transition: color 0.3s;
}

.etherScanLink a:hover {
  color: #ff007a;
  text-decoration-color: #ff007a;
}

.imageButton {
  position: relative;
  cursor: pointer;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
}

.icon {
  font-size: 12px; /* Adjust the size of the icon */
  margin-left: 5px; /* Add some space between the text and the icon */
}

.hovered {
  color: #ff007a; /* Pink color when hovered */
}
